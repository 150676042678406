.b-breadcrumbs{
    padding: 0;
    &__item{
        font-size: 14px;
        & a{
            text-decoration: underline;
        }
        &:after{
            content: '>';
            padding: 0 5px;
        }
        
        &:last-child{
            &:after{
                content: '';
            }
        }
    }
}