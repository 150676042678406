.b-form{
    &__input{
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        outline: 0;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        box-sizing: content-box;
        background-color: transparent;
        padding: .375rem 0; 
        &:hover, &:focus{
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            outline: 0;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid #ced4da;
            border-radius: 0;
            box-sizing: content-box;
            background-color: transparent;
        }
    }
    &__btn{
        padding: 10px 73px 11px !important;
    }
}